.blockchainify {
  margin-top: 107px;
}

.blockchainify__top {
  position: relative;
  z-index: 2;
  text-align: center;
  padding-top: 122px;
}

.blockchainify__top h1 {
  font-weight: 500;
  font-size: 50px;
  line-height: 22px;
  margin-bottom: 35px;
  color: #255056;
}

.blockchainify__top p {
  width: 45%;
  margin: auto;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #000000;
}

.blockchainify__bottom {
  position: relative;
  z-index: 1;
  width: 80%;
  margin: auto;
  margin-top: -7%;
}

.blockchainify__bottom-img {
  display: flex;
  justify-content: center;
}

.blockchainify--img1 {
  width: 100%;
}

.blockchainify--img2 {
  position: absolute;
  width: 10%;
  top: 40%;
  left: 52%;
}


/* ABOUTUS */

.ahoutus__container {
  background-color: #E6B86A;
  overflow: hidden;
  position: relative;

  /* Edox */
  background-image: url('https://media.graphassets.com/c3zn2x8HTaOOMss6fIr6');
  background-repeat: no-repeat;
  background-size: cover;
}

.aboutus {
  position: relative;
  z-index: 2;
}

.aboutus__left {
  height: 100%;
  align-items: center;
  margin: auto;
  padding-right: 100px;
}

.aboutus__left-wrapper {
  text-align: right;
  height: 300px;
  z-index: 2;
}

.aboutus__left-wrapper h1 {
  margin-bottom: 35px;
  font-weight: 500;
  font-size: 50px;
  line-height: 60px;
  color: #255056;
}

.aboutus__left-wrapper p {
  font-size: 20px;
  line-height: 35px;
  color: #061B1C;
}

.aboutus__right {
  height: 100%;
  position: relative;
  padding-left: 110px;
}

.aboutus__card {
  position: relative;
  width: 354px;
  height: 409px;
  margin-bottom: 38px;
  box-shadow: -2px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #FFFFFF;
  cursor: pointer;
}

.aboutus__card:hover {
  transform: translateY(-20px);
  transition: all 0.5s ease-in;
}

.aboutus__right-1 {
  margin-top: 160px;
}

.aboutus__right-2 {
  margin-top: 220px;
}

.aboutus__card img {
  padding: 39px 21px 0 20px;
  object-fit: cover;
}

.aboutus__card-infor span {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #061B1C;
}

.aboutus__card-infor {
  margin: 0 31px 35px 70px;
}

.aboutus__card-infor p {
  font-size: 15px;
  line-height: 22px;
  color: #061B1C;
}

.about-img {
  position: absolute;
  top: 0;
  left: 0px;
}

.Groupabout1 {
  z-index: 0;
  top: 0px;
  left: 0px;
}

.about1 {
  color: #255056;
  z-index: 0;
  top: 0px;
  left: 0px;
}

.about2 {
  color: #255056;
  z-index: 0;
  top: 0px;
}

.Groupaboutdot {
  top: 3%;
  left: 35%;
  opacity: 0.5;
}

.lineabout {
  top: 0px;
  left: 0px;
}

.lineabout3 {
  position: absolute;
  width: 1300px;
  bottom: -19px;
  right: 0;
}

.Groupabout2 {
  position: absolute;
  bottom: 0;
  right: 0px;
  z-index: 1;
}

.lineabout1 {
  width: 1500px;
  position: absolute;
  z-index: 0;
  right: 0;
  bottom: -5%;
}

.lineabout2 {
  position: absolute;
  bottom: 50px;
  left: 2%;
  opacity: 0.5;
}

.aboutline {
  top: 91px;
  left: 118px;
}

.line2 {
  top: 575px;
  left: 157px;
}


.aboutline3 {
  top: 0;
  left: -337px;
  z-index: -1;
}

.aboutline4 {
  top: 0;
  left: 0;
  z-index: -1;
}


/* Our Products */

.ourproducts__container {
  background-color: #FFFFFF;
}

.ourproducts__title {
  text-align: center;
  padding-top: 130px;
}

.ourproducts__title h1 {
  font-weight: 600;
  font-size: 50px;
  line-height: 30px;
  color: #255056;
}

.ourproducts__title p {
  font-size: 20px;
  line-height: 30px;
  color: #061B1C;
}

.ourproducts__bottom {
  position: relative;

  /* Edox */
  padding-top: 60px;
}

.ourproducts__content-wrap {
  position: absolute;
  z-index: 0;
}

.ourproducts__content-wrap-sung {
  top: 0;
  left: 0;
}

.ourproducts__bottom-line {
  position: relative;

}

.ourproducts__bottom-line__list {
  position: relative;
  display: flex;
  align-items: center; 
  justify-content: space-between;
  overflow: scroll;
  padding: 65px 0 120px 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.ourproducts__bottom-line__list::-webkit-scrollbar {
  display: none;
}

.ourproducts__bottom-line__mid-item {
  position: absolute;
  top: 14%;
  left: -5%;
  z-index: 0;
  width: 110%;
}

.ourproducts__bottom-line__mid-item > img{
  width: 100%;
}

.ourproducts__bottom-line__item {
  position: relative;
  cursor: pointer;
}

.itemourproduct3main {
  width: 106px;
  height: 94px;
}

.productlist-img {
  position: absolute;
  z-index: 0;
}


.product__elu-full {
  position: relative;
  background: #163034;
  overflow: hidden;

  /* Edox */
  background-image: url("https://media.graphassets.com/qkxsx7MwRrGL3zn9DGY9");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position-x: center;
  background-position-y: top;
}

.productlist-img1 {
  width: 30%;
  right: 0;
  z-index: 0;
}

.productlist1 {
  width: 38%;
  right: 0;
  top: 0;
}

.productlist2 {
  width: 45%;
  right: 0px;
  top: 0;
}

.productlist3 {
  left: 5%;
  top: -12%;
}

.productlist5 {
  top: 409px;
  right: 68px;
}

.productlist6 {
  bottom: 33px;
  right: 160px;
}

.productlist7 {
  top: 0;
  right: 0px;
}

.productlist__title {
  text-align: center;
  padding-top: 130px;
}

.productlist__title h1 {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 50px;
  line-height: 30px;
  color: #FEFEFE;
  padding-bottom: 30px;
}

.productlist__title p {
  font-size: 20px;
  color: #FEFEFE;
  margin-top: 5px;
}

.angry {
  position: relative;
  margin: 0 30px;
  padding-bottom: 100px;
}

.angry__left img {
  width: 100%;
  object-fit: scale-down;
}

.wrap__angry {
  padding-left: 30px;
  margin-top: 50px;
  height: 100%;
}

.wrap__angry-wrap-item {
  padding-top: 50px;
  padding-left: 82px;
  padding-right: 20px;
  position: absolute;
}

.angry-item__wrap {
  width: 181px;
  height: 194px;
  background: linear-gradient(180deg, #0F3536 0%, #0B686A 100%);
  border: 1px solid #E6B86A;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  text-align: center;
  padding: 0 13px;
}

.angry-item__wrap img {
  padding-top: 12px;
  width: 157px;
  height: 123px;
}

.angry-item__wrap h4 {
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  padding-top: 10px;
}

.angry-item__wrap p {
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  padding-top: 5px;
}
.item-product .item-product__img {
  width: 80%;
  object-fit: scale-down;
}
.backgrourdOurcryvity {
  position: absolute;
  width: 63%;
  margin-left: 5px;
}  
.wrap__angry-btn {
  padding-top: 40%;
  display: flex;
  justify-content: center;
}

.wrap__angry-btn a:hover {
  opacity: 0.8;
  cursor: pointer;
}


.wrap__angry-btn button:hover {
  cursor: pointer;
  box-shadow: 4px 4px 0px 2px rgba(30, 80, 86, 0.8);
  transition: all 0.3s ease-in;
}

.wrap__angry-item {
  margin-left: 10px;
}

.wrap__angry-item:hover {
  cursor: pointer;
  transform: translateY(-20px);
  transition: all 0.3s ease-in;
}

.angry-item__wrap:hover {
  border: 1px solid #163034;
  background: linear-gradient(90.09deg, #E6B86A 1.58%, #CC8C20 98.47%);
}


/* product__mall */

.product__mall-full {
  position: relative;
  height: 900px;
  background-color: white;
}

.product__mall-background {}

.product__mall-container {
  position: relative;
  width: 100%;
}

.product__mall-left {
  width: 40%;
}

.product__mall-right {
  float: right;
  width: 35%;
  top: 0;
}

.product__mall-itemMall {
  position: absolute;
width: 33%;
left: 0;
top: 5%;
z-index: 1;
margin-top: -20px;
object-fit: scale-down;
}

.product__mall-itemMall1 {
  position: absolute;
  width: 44%;
  left: 0;
  bottom: 0;
  z-index: 0;
}

.product__mall-Group375 {
  position: absolute;
  width: 36%;
  right: -6px;
  bottom: 207px;
}

.product__mall-content {
  position: absolute;
  width: 100%;
  padding-bottom: 400px;
  z-index: 2;
  padding-top: 70px;
}

.product__mall-text {
  width: 43%;
  margin: auto;
  text-align: center;
}

.product__mall-content h2 {
  width: 100%;
  margin: auto;
  font-weight: bold;
  font-size: 80px;
  line-height: 22px;
  color: #163034;
}

.ourpatners.grid {
  max-width: 1490px;
  margin: 0 auto;
}

.product__mall-content h3 {
  font-weight: 600;
  font-size: 30px;
  line-height: 22px;
  text-align: center;
  color: #000000;
  margin-top: 60px;
}

.product__mall-content-inf {
  margin: auto;
  z-index: 2;
  margin-top: 50px;
  text-align: center;
  width: 80%;
}

.product__mall-content-inf p {
  font-style: italic;
  font-weight: 600;
  font-size: 17px;
  line-height: 30px;
  text-align: center;
  color: #061B1C;
}

.wrap__angry-btn-blue button {
  margin: auto;
  width: 199px;
  height: 45px;
  background: #255056;
  border: 1px solid #000000;
  box-sizing: border-box;
  margin-top: 45px;
  border-radius: 30px;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #FEFEFE;
}

.wrap__angry-btn-blue button:hover {
  opacity: 0.8;
  cursor: pointer;
  box-shadow: 3px 3px 0px 2px rgba(230, 184, 106, 0.8);
  transition: all 0.3s ease-in;
}


/* ProductBoth */

.product__both {
  background-color: #163034;
  position: relative;

  /* Edox */
  background-image: url('https://media.graphassets.com/WtSVNJoaTXykJesfWPG8');
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-position-x: center;
  background-size: 100% auto;

}

.product__both-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.makeGroupf1 {
  bottom: 0;
  left: 18%;
}

.makeGroupf2 {
  bottom: 10%;
  left: 6%;
}

.makeGroupf3 {
  bottom: 0;
}

.makeGroupf8 {
  position: absolute;
  width: 55%;
  bottom: 0;
  right: 0;
}

.product__both-contents {
  width: 100%;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 100px;
  margin-top: 61px;
}

.product__both-ele {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.product__both-ele .product__hypermon-wrap {
  background: linear-gradient(180deg, #0F3536 0%, #0B686A 100%);
  position: relative;
  z-index: 1;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  margin: 0 12px;
}

.product__hypermon-wrap h2 {
  padding-top: 61px;
  font-weight: bold;
  font-size: 60px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
}

.product__hypermon-wrap img {
  padding-top: 50px;
}

.pepperAttack__img {
  margin-top: -12px;
}

.product__hypermon-wrap:hover {
  transform: translateY(-15px);
  background: linear-gradient(90.09deg, #E6B86A 1.58%, #CC8C20 98.47%);
  transition: all 0.5s ease-in;
}

.product__hypermon-wrap__inf {
  padding: 0 32px;
}

.product__hypermon-wrap__inf p,
ul {
  font-size: 15px;
  line-height: 22px;
  color: #FFFFFF;
}

.product__hypermon-wrap__inf p {
  width: 100%;
  height: 110px;
}

.product__both-btn {
  padding-bottom: 30px;
}

.product__both-btn button {
  background: #E6B86A;
  margin-top: 25px;
  width: 199px;
  height: 45px;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 30px;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #FEFEFE;
}

.product__hypermon-wrap:hover button {
  background: #255056;
}

.product__both-btn button:hover {
  cursor: pointer;
  box-shadow: 4px 4px 0px 2px rgba(230, 184, 106, 0.8);
  transition: all 0.3s ease-in;
}

.ourpatners__content h1 {
  text-align: center;
  font-weight: 500;
  font-size: 50px;
  line-height: 30px;
  color: #255056;
  margin-top: 88px;
  margin-bottom: 30px;
}

.ourpatners__items-wrap {
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 110px;
}

.ourpatner__item {
  filter: grayscale(100%);
  text-align: center;
  object-fit: cover;
}

.ourpatner__item.pt {
  padding-top: 6px;
}

.ourpatner__item:hover {
  filter: grayscale(0);
  cursor: pointer;
  /* box-shadow: -2px 0px 15px rgba(0, 0, 0, 0.1); */
}

.ourproducts__bottom-line__item {
  position: relative;
  text-align: center;
  z-index: 1;
  width: 150px;
  height: 149px;
}

.ourproducts__bottom-line__item-wrap {
  position: relative;
  transition: all 0.5s ease-in;
}

.backgroundElipItem {
  position: absolute;
  z-index: -1;
}

.ourproducts__bottom-line__item h1 {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #255056;
  padding-top: 50px;
  transition: all 0.3s linear;
}

.mallhover {
  top: -26px;
  left: -4px;
  opacity: 0;
  transition: all 0.3s linear;
}

.backgroundElipItem-wrap {
  position: relative;
  transition: all 0.3s linear;
  opacity: 1;
}

.ourproducts__bottom-line__item:hover .backgroundElipItem-wrap {
  opacity: 0;
}

.ourproducts__bottom-line__item:hover .mallhover {
  opacity: 1;
}

.ourproducts__bottom-line__item:hover .mallIMain {
  width: 130px;
  height: 130px;
}

.ourproducts__bottom-line__item:hover h1 {
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  color: #255056;
}

.ourproducts__bottom-img {
  display: flex;
  justify-content: center;
}

/* Edox */
.ourproducts__main-wrap {
  position: relative;
  max-width: 600px;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.ourproducts__main-wrap::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-image: url("https://media.graphassets.com/9nK8NgoMQbiphLm2a01y");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 0;
}
/*----------------*/

.ourproducts__bottom-img img {
  width: 70%;
  z-index: 1;
}


.ourproducts__bottom-inf {
  text-align: center;
  padding-top: 80px;
  align-items: center;
}

.ourproducts__bottom-inf h2 {
  font-weight: bold;
  font-size: 100px;
  color: #E4B460;
  text-shadow: -1px 1px black, 1px 1px black, -1px -1px black, 1px -1px black;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ourproducts__bottom-inf p {
  width: 60%;
  margin: 20px auto 0px;
  font-size: 20px;
  line-height: 35px;
  color: #061B1C;
  min-height: 105px;
}

.productlistx {
  top: 35px;
  right: 500px;
  width: 35.44px;
}

.productlistxleft {
  top: 230px;
  left: 218px;
}

.ourproduct__img-item {
  display: block;
  object-fit: cover;
  height: 85px;
  width: 85px;
  border-radius: 0;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-48%, -55%);
  transition: all .3s linear;
}

