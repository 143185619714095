@media screen and (max-width: 740px){
    .container{
        overflow: hidden;
    }
    .blockchainify{
        /* height: 800px; */
    }
    .blockchainify__top{
        margin:0px 45px 0 45px;
        text-align: center;
    }
    
    .blockchainify__top h1{
        font-size: 30px;
    }

    .blockchainify__top p { 
        width: 100%;
        font-size: 18px;
    }

    .ourproducts__title{
        margin-top: -94px;
    }
    .ourproducts__title h1{
        font-weight: 500;
        font-size: 30px;
        line-height: 30px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 30px;
        color: #255056;
    }

    .ourproducts__title p {
        margin: 0 5px;
        font-size: 18px;
        line-height: 30px;
    }
    .blockchainify__bottom{
        width: 180%;
        margin-left: -40%;
    }
    .blockchainify--img{
        /* top: 15px;
        left: -160px;
        height: 315.89px; */
        
    }
    .blockchainify--img1{
        /* top: -35px;
        left: -217px;
        width: 826.9px;
        height: 522px; */ 
    }
    .aboutus__left{
        margin-top: 80px;
    }

    .aboutus{
        width: 100%;
        padding-bottom: 50px;
    }
    .aboutus__left-wrapper{
        text-align: center;
    }

    .aboutus__left-wrapper h1{
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 60px;
        margin-top: -40px;
    }
    .aboutus__left-wrapper p{
        font-size: 18px;
        line-height: 30px;
    }
    .aboutus__right{
        padding-left: 0;
        padding-bottom: 62px;
    }

    .aboutus__right-2{
        margin-top: 0;
    }
    .aboutus__card{
        height: 409px;
        width: 354px;
        margin: 15px auto ;
    }

    .aboutus__card-mt{
        margin-bottom: 30px;
    }
    .ourproducts{
        height:1000px;
    }

    .ourproducts__bottom-img1{
        width:523.33px;
        height:496.14px;
        top: 0;
        left: -190px;
    }
    .ourproducts__bottom-img2{
        width: 430px;
        height: 430px;
        top: 107px;
        left: -36px;
    }
    .ourproducts__bottom-img3{
        
    }
    .ourproducts__bottom-img4{
        width:301px;
        height:302px;
        top: 170px;
        left: 46px;
    }


    .ourproducts__bottom-wrap{
        
        /* margin-left: 25px; */
        margin-top: 450px;
        
    }
    .ourproducts__bottom-wrap span{
        /* margin-right: 40px; */
        text-align: center;
    
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 45px;
        line-height: 30px;
        /* identical to box height, or 67% */

        text-transform: uppercase;

        /* yeallow */

        color: #E6B86A;

    }
    .ourproducts__bottom-wrap p{
        margin-left: 0;
        width: 322px;
        height: 98px;
        text-align: center;
        padding: 0 15px;


    }
    .ourproducts__bottom-right{
        margin-top: 600px;
    }
    .ourproducts__bottom-right{
        margin-top: 380px;
    }

    .ourproducts__item-wrap{
        position: absolute;
        display: flex;  
        left: -495px; 
    }
    .ourproducts__item--3-img{
        display: none;
    }
    .ourproducts__item--3{
        margin: 0 10px;
    }
    .ourproducts__item--3 span{
        margin-left: 5px;
    }

    .angry{
       /* height:2150px; */
        position: relative;
    }

    .angry__left img{
        top: -64px;
        left: 0;
        width:100%;

    }
    .productlistx {
        top: 19px;
        left: 121px;
        width: 18.02px;
        height: 19.17px;
    }
    .productlistxleft {
        display: none;
    }
    .ourpatner__items{
        position: relative;
        display: flex;
    }
    .ourpatner__items{
        position: absolute;
        left:-28px;
    }
    
    .ourpatner__item{
        width: 147px;
        height: 138px;
    }

    /* .ourpatners{
        height:694px;
     } */
    .footer__logo{
        text-align: center;
    }
    .footer__about h4{
        margin-bottom: 15px;
        font-size:60px;
        margin-left: 25px;
    }
    .footer__about li{
        margin-left: -1px;
    }
    .footer__content-wrap .footer__about {
        margin-top: -100px;
        padding-left: 20px;
    }

    .footer__connect h4{
        display: none;

    }
    .footer__connect{
        text-align: center;
    }
        
    .productlist-img1
    {
        width: 339px;
        height: 205px;
        top: -16px;
        right: -27px;
    }
    .productlist1{ 
        width: 356px;
        height: 176px;
        right: 0;
    }

    .productlist2{
        width: 455px;
        height: 170px;
        top: 0;
    }
    .productlist3,
    .productlist4,.productlist6,.makeg2{
        display: none;
    }

    .productlist5{
        width: 209px;
        height: 229px;
        top: 127px;
        right: 56px;
    }

    .productlist7{
        width: 205px;
        height: 259px;
        top: 30px;
        right: 3px
        ;
    }
    .makeg1{
        width: 100%;
    }
    .header__logo img{
        margin-left: 24px;
    }
    .productlist__title{
        text-align: center;
        padding-top: 0;
    }
    .productlist__title h1{
        font-size: 40px;
        padding-bottom: 116px;
        padding-top: 126px;
    }
    .ourpatners__content h1{
        font-weight: 500;
        font-size: 30px;
        line-height: 30px;
    }
    .ourpatner__item{
        filter: grayscale(100%);

    }
    .ourpatner__item img{
        width: 70%;
        height: 80px;
        object-fit: scale-down;
    }

    .aboutus__right-1 {
        margin-top: 40px;
    }

    .ourproducts__bottom-left{
        text-align: center;
        margin-top: 0px;
        
    }

    .ourproducts__bottom-wrap p{
        width: 100%;
    
    }
    /* @media (min-width: 1113px) {
        .l-l-4{
            max-width: 20.66667%;
        }
     } */
    .ourpatner__item:hover{
        filter: grayscale(0);
        cursor: pointer;
        /* box-shadow: -2px 0px 15px rgba(0, 0, 0, 0.1); */
    }

      /* abouts */
    .about1,.about2,.lineabout1{
        display: none;
    }

    .ourproducts__content-wrap{
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }
    .ourproducts__content-infor{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        margin-top: -70px
    }
    .ourproducts__content-wrap img{
        width: 100%;    
        object-fit: cover;
        margin-top: 110px;
    }

    .ourproducts__content-infor p{
        margin-left: 0;
        text-align: center;
        width: 364px;
        height: 98px;       
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 35px;
        
    }
    .ourproducts__content-infor h1{
        font-size: 45px;
        line-height: 30px;
    }

/*       */
    /*  */
    .productitem5{
        display:none;
    }
    .angry__right h1{
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 60px;
        line-height: 22px;
        /* identical to box height, or 37% */

        text-align: center;

        color: #FFFFFF;
        margin-top: -100px;
    }
    .wrap__angry{
        text-align: center;
        margin-top: 0;
        width: 100%;

    }
    img.backgrourdOurcryvity {
        display:none;
    }
    .wrap__angry-item{
       display: flex;
       justify-content: center;
       margin-bottom: 30px;   
    }
    
    .angry__left-img1{
        top: 0;
    }

    .product__mall-content-inf{
        position: relative;
        z-index: 2;
        margin-top: 33px;
        text-align: center;
        width: 100%;
        padding: 0 10px;
      }
      .product__both-ele{
          padding:0 10px;
      }
      .product__both-ele .product__hypermon-wrap{
       margin: 0 24px;
      }
      .product__hypermon-wrap{
        width: 358px;
        height: 731px;
        border-radius: 10px;
        text-align: center;
        cursor: pointer;
        margin: 0 auto;
        
        
      } 
      .product__hypermon-wrap h2{
        font-weight: 500;
        font-size: 30px;
        line-height: 30px;
      }

      .product__hypermon-wrap img{
          width: 100%;
      }

      .product__hypermon-wrap__inf{
          width: 100%;
      }

      .product__both-btn {
          padding-bottom: 50px;
      }


      .Groupabout2,.aboutline,.lineabout,.Groupabout1,.Groupaboutdot{
          display: none;
      }

      .ourproducts__bottom-line__item {
        margin: 0 15px;
      }

      .ourproducts__bottom-line {
        padding-bottom: 46px;
    }

    .wrap__angry-btn{
        padding-top: 32px;
        /* text-align: right;
        margin-top: 46px; */
      }
      .wrap__angry-btn img{
          width: 76%;
      }
      .item-product .item-product__img {
          padding-bottom: 48px;
          width: 63%;
          padding-right: 20px;
      }
    .product__mall-itemMall{
        width: 57%;
        top: 41px;
        /* left: -157px; */
    }
    .product__mall-itemMall2{
        display:none;
    }
 
    .wrap__angry-btn-blue {
        padding-top: 0px;
        margin-top: -20px;
    }
    .product__mall-itemMall1 {
        display: none;
    }
    .product__mall-full{
        position: relative;
        height: 1000px;
        display: flex;
        background-color: white;
      }
    .product__mall-content{
        padding-top: 200px;
        margin-left: 28px;
      }
    .product__mall-text{
        width: 100%;
        padding-right: 44px;
      }

      .product__mall-content h3{
        width: 100%;
        margin-top: 23px;
      }
      .product__mall-content-inf p{
      width: 70%;
      margin: auto;
      }
      .product__mall-Group375{
          padding-top: 10px;
          width: 90%;
          right: 12px;
          bottom: 2px;
      }
      .product__mall-container {
          height:1000px;
      }
    .product__hypermon-wrap__inf p{
        width: 333px;
        height: 109px;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 22px;
        /* or 147% */
        color: #FFFFFF;
    
    }
    .product__hypermon-wrap__inf{
        padding: 0 0 0 10px;
    }
    .ourpatners__content h1{
        margin-top:50px;
        margin-bottom: 0px;
    }
    .ourpatners__items-wrap{
        margin-top: 20px;
    }
    .ourpatner__item{
        margin-top: 30px;
    }

    .footer__logo {
        margin-top: 59px;
    }


      .blockchainify--img2 {
        /* top: 206px;
        left: 234px;
        width: 55.01px;
        height: 51.06px;     */
    }

    .ourproducts__bottom-img img{
        margin-left: 0;
        width: 344.55px;
        height: 326.18px;
        margin-top: -35px;
    }
    .ourproducts__bottom-inf{
        padding-top: 0;
    }
    .ourproducts__bottom-inf h2 {
        font-size: 332%;
    }
    .ourproducts__bottom-inf-p p{
        padding: 0 10px;
        min-height: 210px;
    }
    .ourproducts__bottom-img{
       text-align: center;
    }

    .angry__left img{
        margin-top: -99px;
        margin-left: 0;
    }
    
    .product__mall-itemMall2{
        
    }
    .wrap__angry-wrap-item{
        left: 0;
    }
    /* .product__mall-itemMall,
    .product__mall-itemMall1
    {
        display: none;
    } */
    
    .product__mall-content h2 {
        padding-top: 90px;
        font-size: 50px;
    }
    .product__mall-Group375{
        display: block;
    }
    .footer__about h4{
        margin-left: 0;
    }
    /* .ourproducts__bottom-inf-wrap{
        padding-left:  0;
    } */

    .ourproducts__bottom-line__list{
        padding-left: 0;
    }
    .makeGroupf8{
        display: block;
    }
    .productlistxleft{
        display: none;
    }
    .product__mall-itemMall8{
        display: none;
    }

}