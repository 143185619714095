
.header{
    height: 107px;
    background-color:#255056;
    border-bottom: 5px solid #E6B86A;
    width: 100%;
    position:fixed;
    top:0;
    z-index: 99999999999;
    
}
.header__logo{
    align-items: center;
}
.header__logo img{
    object-fit: contain;
}

.header__nav ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
}
.header__nav a {
    text-decoration: none;
    color:white;
    font-style: normal;
    font-weight: 900;
    font-size: 15px;
    line-height: 22px;
}

.header__wrapper{
    align-items: center;
    height: 107px;

}
.header__nav-item{
    /* margin-right: 75px; */
    padding: 5px;
}

.header__nav-item:hover{
    opacity: 0.7;
}

.header__nav-item.active a {
    color: #E6B86A;
}

.header__bars{
    display: none;
}


@media screen and (max-width: 740px){
    .header__nav{
        position:absolute;
        right:0;
        height:92vh;
        top:107px;
        background-color:#255056;
        z-index: 99999;
        display:flex;
        flex-direction:column;
        align-items: center;
        width: 50%;
        border:1px solid #ccc;
        transform: translateX(100%);
        transition: transform 0.5s ease-in;
        display: none;
        
    }
    .header__nav ul{
        display:flex;
        flex-direction:column;
        align-items: center;
    }

    .nav_active{
        transform: translateX(0%);
        display:block;
        transition: all 0.5s ease-in;
        
    }
    .header__nav-item{
        padding: 15px;
    }
    .header__bars{
        padding:5px;
        display: block;
    }
    .header__bars i{
        color: #E6B86A;
        cursor: pointer;
        
    }
}
