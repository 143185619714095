.footer{
    background-color:#F0F0F0;
}
.footer__content{
    border-bottom: 1px solid #D7D7D7;
}

.footer__logo{
    margin-top: 119px;
}
.footer__infor{
    margin-top:50px;
    text-align: center;
    padding-bottom: 71px;
}

.footer__infor p{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 25px;
    color: #255056;
}

.footer__about{
    padding-top: 138px;
    padding-left: 50px;
}
.footer__about h4,
.footer__infor h4{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #255056;
    padding-bottom:24px;
    margin-left: 8px;

}

.footer__about ul {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 32px;
    color: #255056;
    margin-left: 28px;
    
}
.footer__about ul li{
    text-align: left;
}
.footer__connect i {
    font-size: 25px;
    color: #255056;
    
}

.footer__icon-connect{
    margin:0 9px;
    font-size: 20px;
    color: #255056;
}
.footer__content-wrap{
    padding-bottom: 70px;
}

.footer__infor.mobile p{
    display: none;
}
.footer__about-address{
    display: flex;
    margin-left: 8px;
}
.footer__about-address i,p{
    color: #06454B;
}
.footer__about-address i{
    font-size: 25px;
    margin-right: 15px;
}

.footer__about-address p{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 32px;
    color: #06454B;
    width: 224px;
}
.footer_copyright h3 {
    text-align: center;
    font-size: 12px;
    line-height: 25px;
    color: #06454B;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
}
.footer_copyright h3 span {
    display: block;
    font-size: 15px;
}

