@media screen and (max-width: 740px){
    .product__heading-wrap  h1{
        font-weight: 500;
        font-size: 30px;
        line-height: 22px;      
    }
    .product__heading-link p{
        font-size: 20px;
        line-height: 30px;
    }
    
    .products__item-left .backgrourd{
        width: 150px;
        height: 150px;
        object-fit: scale-down;
    }
    .products__item-left .mainproductv5{
        width: 176px;
        height: 120px;
        object-fit: scale-down;
    }
    .products__item-right span{
        font-weight: 500;
        font-size: 13px;
        line-height: 13px;
    }
    
    .products__item-right h5{
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
    }
    
    .products__item-right p{
        font-size: 13px;
        line-height: 20px;
        max-width: 90%;
    }
    .products__item-right-icon{
        padding-top: 30px;
    }
    .products__item-right-icon img{
        width: 30px;
        height: 30px;
    }
    
    .product__heading-wrap {
        text-align: center;
        padding-top: 40px;
        
    }
    
    .title__heading-group17{
        
    
    }
    .title__heading-group6{
        display: none;
    }
    .title__heading-group7{
        display: none;
    }
    .title__heading-group16{
        display: none;
    }
    .title__heading-group1{
        display: none;
    }
    .title__heading-group3{
        display: none;
    }
    .title__heading-group2{
        display: none;
    }
    .title__heading-group4{
        display: none;
    }
    .title__heading-group5{
        display: none;
    }
    .title__heading-group9{
        display: none;
    }
    .title__heading-group8{
        display: none;
    }
    .title__heading-group10{
        display: none;
    }
    .title__heading-group11{
        display: none;
    }
    .title__heading-group12{
        display: none;
    }
    .title__heading-group13{
        right: 100px;
    }
    .title__heading-group14{
    
    }
    .title__heading-group15{
        display: none;
    }
    
    
    .team__personel--list{
        padding-left: 0px !important;
    }
    .team__personel--item-wrap{
        max-width:209.5px !important;
    }
    .team__personel--item__inf .team__personel--item-img-avt{
        width: 71% !important;
        margin-left: -32px !important; 
    }
    
    .team__wrapper-mid__content {
        width: 96% !important;
        height: 100% !important;
        margin: 0 auto;
    }
    
    .team__wrapper-mid-2.mb{
        display: block !important;
    }
    .team__wrapper-mid-2.mb .img-mid{
    }
    
    .team__wrapper-mid-2.pc{
        display: none !important;
    }
    .team__wrapper-mid-1 img{
    }
    .item1{
        display:none;
    }
    .item2{
        display:none;
    }
    .item3{
        display: none;
    }
    .team__wrapper-mid-3 img{
        width: 90% !important;
        padding-right: 25px !important;
    }
    
    .team__personel--item__connect .connect-icon{
        padding: 0px !important;
        margin-left: 0px !important;
    }
    .team__personel--item__bg{
        width: 90% !important;
    }
    .team__personel--item__inf, .team__personel--item__connect{
        max-width: 93% !important;
    }
    
    .team__personel--item-wrap{
        align-items: center !important;
        justify-content:center !important;       
    }
    .team__personel--item-wrap{
        margin: 0 auto !important;
        margin-top: 15px !important;
        
    }
    
    .products__item-wrap{
        width:100% !important;
    }
    /* Edox */
    .grid > .products__container {
        background-size: 400% auto;
    }

    .grid > .team__container {
        background-size: 400% auto;
    }
    }
    /*----------*/
    
@media screen and (min-width: 740px) and (max-width: 1023px){
    .container{
        overflow: hidden;
        }
    .header{
    }
    
    .header__nav{
        position:absolute;
        right:0;
        height:92vh;
        top:107px;
        background-color:#255056;
        z-index: 99999;
        display:flex;
        flex-direction:column;
        align-items: center;
        width: 50%;
        border:1px solid #ccc;
        transform: translateX(100%);
        transition: transform 0.5s ease-in;
        display: none;
        
    }
    .header__nav ul{
        display:flex;
        flex-direction:column;
        align-items: center;
    }
    
    .nav_active{
        transform: translateX(0%);
        display:block;
        transition: all 0.5s ease-in;
        
    }
    .header__nav-item{
        padding: 15px;
    }
    .header__bars{
        padding:5px;
        display: block;
    }
    .header__bars i{
        color: #E6B86A;
        cursor: pointer;
        
    }
    
    
    .blockchainify{
        height: 800px;
    
    }
    .blockchainify__top{
        
        margin:0px 45px 0 45px;
        text-align: center;
    
    
    }
    .blockchainify__top h1{
        font-weight: 500;
        font-size: 30px;
        line-height: 22px;
    }
    
    .blockchainify__top p { 
    
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 30px;
    
        /* or 167% */
    
    
        color: #000000;
    }
    .ourproducts__title{
        margin-top: -94px;
    }
    .ourproducts__title h1{
        font-weight: 500;
        font-size: 30px;
        line-height: 30px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 30px;
        color: #255056;
    }
    
    .ourproducts__title p {
        margin: 0 5px;
        font-size: 18px;
        line-height: 30px;
    }
    .blockchainify--img{
        top: 15px;
        left: -160px;
        height: 315.89px;
        
    }
    .blockchainify--img1{
            
            width: 826.9px;
            height: 522px;
        
    }
    .aboutus__left{
        margin-top: 80px;
    }
    
    .aboutus{
        height:100%;
        padding-bottom: 50px;
    }
    .aboutus__left-wrapper{
        text-align: center;
    }
    
    .aboutus__left-wrapper h1{
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 60px;
        margin-top: -40px;
    }
    .aboutus__left-wrapper p{
        font-size: 18px;
        line-height: 30px;
    }
    .aboutus__right{
        padding-left: 0;
        padding-bottom: 62px;
    }
    
    .aboutus__right-2{
        margin-top: 0;
    }
    .aboutus__card{
        height: 409px;
        width: 354px;
        margin: 15px auto ;
    }
    
    .aboutus__card-mt{
        margin-bottom: 30px;
    }
    .ourproducts{
        height:1000px;
    }
    
    .ourproducts__bottom-img1{
        width:523.33px;
        height:496.14px;
        top: 0;
        left: -190px;
    }
    .ourproducts__bottom-img2{
        width: 430px;
        height: 430px;
        top: 107px;
        left: -36px;
    }
    .ourproducts__bottom-img3{
        
    }
    .ourproducts__bottom-img4{
        width:301px;
        height:302px;
        top: 170px;
        left: 46px;
    }
    
    
    .ourproducts__bottom-wrap{
        
        /* margin-left: 25px; */
        margin-top: 450px;
        
        }
    .ourproducts__bottom-wrap span{
        /* margin-right: 40px; */
        text-align: center;
    
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 45px;
        line-height: 30px;
        /* identical to box height, or 67% */
    
        text-transform: uppercase;
    
        /* yeallow */
    
        color: #E6B86A;
    
    }
    .ourproducts__bottom-wrap p{
        margin-left: 0;
        width: 322px;
        height: 98px;
        text-align: center;
        padding: 0 15px;
    
    
    }
    .ourproducts__bottom-right{
        margin-top: 600px;
    }
    .ourproducts__bottom-right{
        margin-top: 380px;
    }
    
    .ourproducts__item-wrap{
        position: absolute;
        display: flex;  
        left: -495px; 
    }
    .ourproducts__item--3-img{
        display: none;
    }
    .ourproducts__item--3{
        margin: 0 10px;
    }
    .ourproducts__item--3 span{
        margin-left: 5px;
    }
    
    .angry{
        height: 1302px;
        position: relative;
    }
    
    .angry__left img{
        top: -64px;
        left: 0;
    }
    
    .productlist{
    height: 6468px;
    }
    
    .ourpatner__items{
        position: relative;
        display: flex;
    }
    .ourpatner__items{
        position: absolute;
        left:-28px;
    }
    
    .ourpatner__item{
        width: 147px;
        height: 138px;
    }
    
    /* .ourpatners{
        height:687px;
    } */
    .footer__logo{
        text-align: center;
    }
    .footer__about h4{
        margin-bottom: 15px;
        font-size:60px;
        margin-left: 25px;
    }
    .footer__about li{
        margin-left: 25px;
    }
    
    .footer__about{
        margin-top: -100px;
    }
    .footer__connect h4{
        display: none;
    
    }
    .footer__connect{
        text-align: center;
    }
        
    
    
    .productlist-img1
    {
        width: 250px;
        height: 210px;
        top: -16px;
        right: -27px;
    }
    .productlist1{
        
        width: 272px;
        height: 154px;
        right: 0;
    }
    
    .productlist2{
        width: 307px;
        height: 116px;
        top: 0;
    }
    .productlist3,
    .productlist4,.productlist6,.makeg2{
        display: none;
    }
    
    .productlist5{
        width: 128px;
        height: 128px;
        top: 127px;
        right: 22px;
    }
    
    .productlist7{
        width: 172px;
        height: 184px;
        top: 30px;
        right: -27px;
    }
    .makeg1{
        width: 100%;
    }
    .header__logo img{
        margin-left: 24px;
    }
    .productlist__title h1{
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 30px;
    
        /* wihite */
    
        color: #FEFEFE;
    }
    .productlist__title p{
        font-size: 18px;
        line-height: 30px;
    }
    .ourpatners__content h1{
        font-weight: 500;
        font-size: 30px;
        line-height: 30px;
    }
    .ourpatner__item{
        filter: grayscale(100%);
    
    }
    .ourpatner__item img{
        max-width: 80%;
        height: 90.33px;
        }
    
        .aboutus__right-1 {
        margin-top: 40px;
    }
    
    .ourproducts__bottom-left{
        text-align: center;
        margin-top: 0px;
        
        }
    
    .ourproducts__bottom-wrap p{
        width: 100%;
        
    }
    .ourpatner__item:hover{
        filter: grayscale(0);
        cursor: pointer;
        /* box-shadow: -2px 0px 15px rgba(0, 0, 0, 0.1); */
        }
    
        /* abouts */
        .about1,.about2,.lineabout1{
            display: none;
        }
    
        .ourproducts__content-wrap{
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }
    .ourproducts__content-infor{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        margin-top: -70px
    }
    .ourproducts__content-wrap img{
        width: 100%;    
        object-fit: cover;
        margin-top: 110px;
    }
    
    .ourproducts__content-infor p{
        margin-left: 0;
        text-align: center;
        width: 364px;
        height: 98px;       
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 35px;
        
    }
    .ourproducts__content-infor h1{
        font-size: 45px;
        line-height: 30px;
    }
    
    .product__seemore h1{
        margin-top: 90px;
    }
    
    /*       */
    /*  */
    .productitem5{
        display:none;
    }
    .angry__right h1{
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 60px;
        line-height: 22px;
        /* identical to box height, or 37% */
    
        text-align: center;
    
        color: #FFFFFF;
        margin-top: -100px;
    }
    .wrap__angry{
        text-align: center;
        margin-top: 0;
        width: 100%;
    
    }
    .wrap__angry-item{
    
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    
        
    }
    
    .angry__left-img1{
        top: 0;
    }
    .product__mall-itemMall,.product__mall-itemMall2,.product__mall-itemMall3{
        /* display: none; */
    }
    
    
    .product__mall-content-inf{
        position: relative;
        z-index: 2;
        margin-top: 50px;
        
        text-align: center;
        width: 100%;
        padding: 0 10px;
        }
        .product__hypermon{
            padding:0 10px;
        }
        .product__hypermon-wrap{
        height: 100%;
        border-radius: 10px;
        text-align: center;
        cursor: pointer;
        margin: 0 auto;
        
        
        } 
        .product__hypermon-wrap h2{
        font-weight: 500;
        font-size: 30px;
        line-height: 30px;
        }
    
        .product__hypermon-wrap img{
            width: 100%;
        }
    
        .product__hypermon-wrap__inf{
            width: 100%;
        }
    
        .product__both-btn {
            padding-bottom: 50px;
        }
    
        .Groupabout2,.aboutline,.lineabout,.Groupabout1,.Groupaboutdot{
            display: none;
        }
    
        .ourproducts__bottom-line__item {
        margin: 0 15px;
        }
    
        .ourproducts__bottom-line {
        padding-bottom: 46px;
    }
    .item1{
        display: none;
    }
    .item2{
        display: none;
    }
    .item3{
        display: none;
    }
    .wrap__angry-btn{
        margin-top: 40px;
        text-align: center;
        padding-top: 32px;
    }
    
    .product__mall-itemMall{
        left: -157px;
    }
    .product__mall-itemMall2{
        display:none;
    
    }
    .product__mall-itemMall3{
    
    }
    .product__mall-itemMall3{
        /* display:none; */
        margin-top: 383px;
        width: 95%;
        left: 0;
    }
    
    .wrap__angry-btn-blue {
        padding-top: 0px;
    }
    .product__mall-content{
        padding-bottom: 94px;
    }
    
    .product__hypermon-wrap__inf p{
        width: 95%;
        height: 140px;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 22px;
        /* or 147% */
        
        
        color: #FFFFFF;
    
    }
    .product__hypermon-wrap__inf{
        padding: 0 0 0 10px;
    }
    .ourpatners__content h1{
        margin-top:50px;
        margin-bottom: 0px;
    }
    .ourpatners__items-wrap{
        margin-top: 20px;
    }
    .ourpatner__item{
        margin-top: 30px;
    }
    
    .footer__logo {
        margin-top: 59px;
    }
    
    
        .blockchainify--img2 {
        top: 123px;
        left: 348px;
        width: 55.01px;
        height: 51.06px; 
    }
    
    .ourproducts__bottom-img img{
        margin-left: 0;
        width: 344.55px;
        height: 326.18px;
        margin-top: -35px;
    }
    .ourproducts__bottom-inf{
        padding-top: 0;
    }
    .ourproducts__bottom-inf-p p{
        padding: 0 10px;
    }
    .ourproducts__bottom-img{
        text-align: center;
    }
    
    .angry__left img{
        margin-top: -99px;
        margin-left: 0;
    }
    .item-product img {
        padding-left:0 ;
        }
    
    .item-product{
        padding-top: 40px;
    }
    .product__mall-itemMall2{
        
    }
        
    .wrap__angry-wrap-item{
        left: 0;
    }
    .product__mall-itemMall7,
    .product__mall-itemMall2,
    .product__mall-itemMall3,
    .product__mall-itemMall5,
    .product__mall-itemMall3,
    .product__mall-itemMall6
    {
        display: none;
    }
    .product__mall-Group374{
        display: block;
        position: absolute;
        top: 0;
        left: 0;
    }
    .product__mall-content h2 {
        padding-top: 150px;
    }
    .product__mall-Group375{
        display: block;
    }
    .footer__about h4{
        margin-left: 0;
    }
    .ourproducts__bottom-inf-wrap{
        padding-left:  0;
        }
    
        .ourproducts__bottom-line__list{
            padding-left: 0;
        }
    
        .makeGroupf8{
        display: block;
        }
        /* .makeGroupf5,.makeGroupf6,.makeGroupf4{
        display: none;
    }
    .makeGroupf8{
        display: block;
    } */
    .productlistxleft{
        display: none;
    }
    .product__mall-itemMall8{
        display: none;
    
    }
    
    /* Product */
    
    
    .product__heading-wrap  h1{
        font-weight: 500;
        font-size: 30px;
        line-height: 22px;
    }
    .product__heading-link p{
        font-size: 20px;
        line-height: 30px;
    }
    
    .products__item-left .backgrourd{
        width: 39%;
    }
    .products__item-left .mainproductv5{
        width:100%;
    
    }
    .products__item-right span{
        font-weight: 500;
        font-size: 13px;
        line-height: 13px;
    }
    
    .products__item-right h5{
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
    }
    
    .products__item-right p{
        font-size: 13px;
        line-height: 20px;
        max-width: 90%;
    }
    .products__item-right-icon{
        padding-top: 30px;
    }
    .products__item-right-icon img{
        width: 30px;
        height: 30px;
    }
    
    .product__heading-wrap {
        text-align: center;
        padding-top: 40px;
        
    }
    .group235{
        padding-left: 100px;
    }
    
    
    .title__heading-group17{
        
    
    }
    .title__heading-group6{
        display: none;
    }
    .title__heading-group7{
        display: none;
    }
    .title__heading-group16{
        display: none;
    }
    .title__heading-group1{
        display: none;
    }
    .title__heading-group3{
        display: none;
    }
    .title__heading-group2{
        display: none;
    }
    .title__heading-group4{
        display: none;
    }
    .title__heading-group5{
        display: none;
    }
    .title__heading-group9{
        display: none;
    }
    .title__heading-group8{
        display: none;
    }
    .title__heading-group10{
        display: none;
    }
    .title__heading-group11{
        display: none;
    }
    .title__heading-group12{
        display: none;
    }
    .title__heading-group13{
        right: 100px;
    }
    .title__heading-group14{
    
    }
    .title__heading-group15{
        display: none;
    }
    
    .team__personel--list{
        padding-left: 0px !important;
    }
    .team__personel--item-wrap{
        max-width:235.5px !important;
    }
    .team__personel--item__inf .team__personel--item-img-avt{
        width: 71% !important;
        margin-left: -29px !important;
    }
    
    .team__wrapper-mid__content {
        width: 90% !important;
        height: 100% !important;
        margin: 0 auto;
    }
    
    .team__wrapper-mid-2.mb{
        display: block !important;
    }
    .team__wrapper-mid-2.mb .img-mid{
        width: 572px !important;
    }
    
    .team__wrapper-mid-2.pc{
        display: none !important;
    }
    .team__wrapper-mid-1 img{
        width: 90% !important;
    
    }
    
    .team__wrapper-mid-3 img{
        width: 90% !important;
        padding-right: 25px !important;
    }
    
    .team__personel--item__connect .connect-icon{
        padding: 0px !important;
        margin-left: 0px !important;
    }
    .team__personel--item__bg{
        width: 90% !important;
    }
    .team__personel--item__inf, .team__personel--item__connect{
        max-width: 90% !important;
    }
    .team__personel--item{   
    }
    
    .team__personel--item-wrap{
        margin: 0 auto !important;
        margin-top: 15px !important;
    }
    .team__personel--item__inf h2 {
        margin-top: 0 !important;
        }
    
    /* Edox */
    .grid > .products__container {
        background-size: 300% auto;
    }
    .grid > .team__container {
        background-size: 300% auto;
    }
    /*----------*/
}

@media screen and (min-width: 1024px) and (max-width: 1365px){
    .wrap__angry-item{
        margin-right: 15px;
    }

    /* Edox */
    .grid > .products__container {
        background-size: 200% auto;
    }
    .grid > .team__container {
        background-size: 200% auto;
    }
    /*-----------*/
}
