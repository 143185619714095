* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
  }
  
  body {
    font-family: 'Poppins', sans-serif;
  }
  
  .App {
    overflow: hidden;
  }